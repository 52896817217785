import {
  LOAD_FILTER_OPTIONS,
  SET_FILTERS,
  SET_SORT_PREFERENCES,
} from '../actions';
import { getCurrentPeriod, getCurrentYear } from '../../utils/dateUtils';
import { sortDirectionOptions } from '../../referenceData';
import { sortConfig } from '../../utils';

const initialState = {
  options: {},
  selectedFilters: {
    year: getCurrentYear(),
    period: getCurrentPeriod(),
  },
  sortPreferences: {
    availableSortColumns: sortConfig,
    selectedSortColumns: [],
    draggedItem: {},
    sortDir: sortDirectionOptions[0],
  },
};

const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_FILTER_OPTIONS: {
      return { ...state, options: { ...state.options, ...action.data } };
    }
    case SET_FILTERS: {
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, ...action.payload },
      };
    }
    case SET_SORT_PREFERENCES: {
      return { ...state, sortPreferences: { ...action.payload } };
    }
    default:
      return state;
  }
};

export default FilterReducer;
