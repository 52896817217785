import axios from '../axios';
import { LOAD_USER_INFO } from '../../store/actions';

const loadUserInfo = (dispatch) => {
  axios.get('/user/me').then((res) => {
    dispatch({ type: LOAD_USER_INFO, data: res.data });
  });
};

export default {
  loadUserInfo,
};
