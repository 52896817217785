import _ from 'lodash';
import { ALL, MATCH_STATUS, PERIOD_LIST } from '../referenceData';
import { getYearOptions } from './dateUtils';
import gridConfig from '../components/features/Transactions/gridConfig';

export const getGroupingState = (dataState) =>
  _.omit(dataState, ['take', 'skip']);

export const getFiltersConfig = (companies, filters, accounts, matchIds) => {
  return [
    {
      label: 'Company',
      name: 'company',
      options: [...companies, ALL],
      defaultValue: ALL,
    },
    {
      label: 'Year',
      name: 'year',
      options: getYearOptions(3),
      defaultValue: filters.year,
    },
    {
      label: 'Period',
      name: 'period',
      options: PERIOD_LIST,
      className: 'flex mt-2',
      defaultValue: filters.period,
    },
    {
      label: 'Match Status',
      name: 'matchStatus',
      options: [...Object.values(MATCH_STATUS), ALL],
      defaultValue: ALL,
    },
    {
      label: 'Account',
      name: 'account',
      options: [...accounts, ALL],
      defaultValue: ALL,
    },
    {
      label: 'Match ID',
      name: 'matchId',
      options: [...matchIds, ALL],
      defaultValue: ALL,
    },
  ];
};

export const onFilterChange =
  (filters, setFilters, loadAccountsOnPeriodChange, loadMatchIds) => (evt) => {
    const { name } = evt.target.props;
    const { value } = evt.target;

    const newFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(newFilters);

    const newFilterPrams = _.omitBy(newFilters, (val) => val === ALL);
    if (name === 'matchStatus') {
      loadMatchIds(newFilterPrams);
    }

    if (name === 'year' || name === 'period') {
      loadAccountsOnPeriodChange(newFilterPrams);
      loadMatchIds(newFilterPrams);
    }
  };

export const getUniqueId = () => Date.now().toString(10);

export const getCreditDebitTally = (records) =>
  records
    ? Object.keys(records).reduce((acc, id) => {
        return acc + (records[id].creditAmount - records[id].debitAmount);
      }, 0)
    : 0;

export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const fieldsToExcludeFromSort = [
  'workdayId',
  'transactionDebitMinusCreditAmount',
];
export const sortConfig = gridConfig.columns
  .filter((item) => fieldsToExcludeFromSort.indexOf(item.field) === -1)
  .map((item) => ({
    name: item.title,
    id: camelToSnakeCase(item.field),
    selected: false,
  }));
