import { OktaAuth } from '@okta/okta-auth-js';
import environment from '../utils/environment';

export default class AuthService {
  static #oktaAuth;

  static clearTokenManager() {
    AuthService.#oktaAuth.tokenManager.clear();
  }

  static sendRevokeTokenBeacon(formData) {
    formData.append('client_id', environment.getClientId());
    navigator.sendBeacon(
      `${environment.getOktaUrl()}/oauth2/v1/revoke`,
      formData
    );
  }

  static revokeTokenBeacon() {
    const tokensToRevoke = {
      access_token: AuthService.#oktaAuth.getAccessToken(),
      refresh_token: AuthService.#oktaAuth.getRefreshToken(),
    };
    Object.keys(tokensToRevoke).forEach((item) => {
      const formData = new URLSearchParams();
      formData.append('token_type_hint', item);
      formData.append('token', tokensToRevoke[item]);
      AuthService.sendRevokeTokenBeacon(formData);
    });

    AuthService.clearTokenManager();
  }

  static revokeTokens() {
    AuthService.#oktaAuth.revokeAccessToken();
    AuthService.#oktaAuth.revokeRefreshToken();
    AuthService.clearTokenManager();
  }

  static triggerLogin = () => {
    AuthService.getOktaAuth().signInWithRedirect();
  };

  static getOktaAuth() {
    if (!AuthService.#oktaAuth) {
      AuthService.#oktaAuth = new OktaAuth({
        issuer: environment.getOktaUrl(),
        clientId: environment.getClientId(),
        redirectUri: `${window.location.origin}/`,
        scopes: ['openid', 'email', 'offline_access', 'profile'],
        pkce: true,
        responseMode: 'query',
      });
    }

    return AuthService.#oktaAuth;
  }
}
