export const ALL = 'All';
export const COMPANIES = [
  'LE001',
  'LE002',
  'LE003',
  'LE005',
  'LE007',
  'All Companies',
];
export const PERIOD_LIST = [
  'Jan-01',
  'Feb-02',
  'Mar-03',
  'Apr-04',
  'May-05',
  'Jun-06',
  'Jul-07',
  'Aug-08',
  'Sep-09',
  'Oct-10',
  'Nov-11',
  'Dec-12',
];

export const GL_APPROVER = 'GL_APPROVER';
export const GL_REVIEWER = 'GL_REVIEWER';

export const MATCH_STATUS = {
  MATCHED: 'Matched',
  UNMATCHED: 'Unmatched',
  REVIEWED: 'Reviewed',
  APPROVED: 'Approved',
};

export const USER_ACTION = {
  APPROVE: 'approve',
  REJECT: 'reject',
  MANUAL_MATCH: 'manual_match',
  REVIEW: 'review',
};

export const ACTION_ALLOWED_STATUS_MAPPING = {
  GL_APPROVER: {
    [USER_ACTION.APPROVE]: MATCH_STATUS.REVIEWED,
    [USER_ACTION.REJECT]: MATCH_STATUS.REVIEWED,
  },
  GL_REVIEWER: {
    [USER_ACTION.REVIEW]: MATCH_STATUS.MATCHED,
    [USER_ACTION.MANUAL_MATCH]: MATCH_STATUS.UNMATCHED,
    [USER_ACTION.REJECT]: MATCH_STATUS.MATCHED,
  },
};

// TODO remove this and read updated status from response once available in API response
export const ACTION_UPDATED_STATUS_MAPPING = {
  [USER_ACTION.APPROVE]: MATCH_STATUS.APPROVED,
  [USER_ACTION.REVIEW]: MATCH_STATUS.REVIEWED,
  [USER_ACTION.REJECT]: MATCH_STATUS.UNMATCHED,
  [USER_ACTION.MANUAL_MATCH]: MATCH_STATUS.REVIEWED,
};

export const ROW_COUNT = 50;

export const ALLOWED_FILE_TYPES = [
  '.jpg',
  '.png',
  '.xls',
  '.xlt',
  '.xla',
  '.xlsx',
  '.xltx',
  '.xlsm',
  '.xltm',
  '.xlam',
  '.csv',
  '.pdf',
];

export const sortDirectionOptions = [
  { label: 'Ascending', id: 'asc' },
  { label: 'Descending', id: 'desc' },
];

export const ROUTES = {
  LOGIN: '/login',
  HOME: '/',
};

export const ACTIVE_TABS = 'activeTabs';
