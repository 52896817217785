export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const LOAD_COMMENTS = 'LOAD_COMMENTS';
export const POST_COMMENT_SUCCESS = 'POST_COMMENT_SUCCESS';
export const LOAD_FILTER_OPTIONS = 'LOAD_FILTER_OPTIONS';
export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SELECT_ROW = 'SELECT_ROW';
export const SELECT_ALL = 'SELECT_ALL';
export const UNSELECT_ROW = 'UNSELECT_ROW';
export const RESET_SELECTION = 'RESET_SELECTION';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const SET_ERROR = 'SET_ERROR';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_COMMENT = 'SET_COMMENT';
export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const SET_SORT_PREFERENCES = 'SET_SORT_PREFERENCES';
export const LOAD_AUDIT_HISTORY = 'LOAD_AUDIT_HISTORY';
export const RESET_PAGE_SIZE = 'RESET_PAGE_SIZE';

export const showLoader = () => ({ type: SHOW_LOADER });
export const hideLoader = () => ({ type: HIDE_LOADER });
export const setError = (msg) => ({ type: SET_ERROR, msg });
