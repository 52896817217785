import React from 'react';
import PropTypes from 'prop-types';

const style =
  'h-10 my-2 px-3 mx-1 text-2xl border-primary rounded-lg border mt-2 hover:bg-gray-500 hover:text-primary hover:underline cursor-pointer text-black';

const invertOnHoverStyle =
  'h-10 px-3 mx-1 border-primary rounded-lg border hover:bg-primary-dark hover:text-white hover:underline cursor-pointer text-primary';

const Button = ({ children, onClick, invertOnHover, className }) => (
  <button
    type="button"
    className={`${invertOnHover ? invertOnHoverStyle : style} ${
      className || ''
    }`}
    style={{ backgroundColor: 'whitesmoke' }}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  invertOnHover: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  invertOnHover: undefined,
  className: undefined,
};

export default Button;
