import React from 'react';
import {
  ListBox,
  processListBoxData,
  processListBoxDragAndDrop,
} from '@progress/kendo-react-listbox';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getSortToolBar } from './SortToolbar';
import { SET_SORT_PREFERENCES } from '../../../store/actions';
import { sortDirectionOptions } from '../../../referenceData';

const SELECTED_FIELD = 'selected';

const SortPreferences = ({ state, setState }) => {
  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.name === event.dataItem.name) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }

        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    const toolName = e.toolName || '';
    const result = processListBoxData(
      state.availableSortColumns,
      state.selectedSortColumns,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      availableSortColumns: result.listBoxOneData,
      selectedSortColumns: result.listBoxTwoData,
    });
  };

  const handleDragStart = (e) => {
    setState({ ...state, draggedItem: e.dataItem });
  };

  const handleDrop = (e) => {
    const result = processListBoxDragAndDrop(
      state.availableSortColumns,
      state.selectedSortColumns,
      state.draggedItem,
      e.dataItem,
      'name'
    );
    setState({
      ...state,
      availableSortColumns: result.listBoxOneData,
      selectedSortColumns: result.listBoxTwoData,
    });
  };

  return (
    <>
      <div className="flex">
        <div className="font-bold text-3xl ml-4 pb-4 mr-5">Sort By</div>
        <DropDownList
          name="sortDir"
          value={state.sortDir}
          className="float-right mb-5"
          onChange={(e) => setState({ ...state, sortDir: e.value })}
          defaultValue={sortDirectionOptions[0]}
          data={sortDirectionOptions}
          dataItemKey="id"
          textField="label"
        />
      </div>

      <div className="flex pb-10" style={{ maxWidth: 300 }}>
        <div style={{ minWidth: 300 }}>
          <ListBox
            style={{
              height: 205,
              width: '100%',
            }}
            data={state.availableSortColumns}
            textField="name"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, 'availableSortColumns', 'selectedSortColumns')
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            toolbar={getSortToolBar(state, handleToolBarClick)}
          />
        </div>
        <div className="ml-5" style={{ minWidth: 300 }}>
          <ListBox
            style={{
              height: 205,
              width: '100%',
            }}
            data={state.selectedSortColumns}
            textField="name"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, 'selectedSortColumns', 'availableSortColumns')
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
      </div>
    </>
  );
};

SortPreferences.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
};

const mapStoreToProps = (store) => ({
  state: store.filters.sortPreferences,
});

const mapDispatchToProps = (dispatch) => ({
  setState: (state) => dispatch({ type: SET_SORT_PREFERENCES, payload: state }),
});

export default connect(mapStoreToProps, mapDispatchToProps)(SortPreferences);
