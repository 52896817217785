import { ListBoxToolbar } from '@progress/kendo-react-listbox';
import React from 'react';

export const getSortToolBar = (state, handleToolBarClick) => () =>
  (
    <ListBoxToolbar
      tools={[
        'moveUp',
        'moveDown',
        'transferTo',
        'transferFrom',
        'transferAllTo',
        'transferAllFrom',
      ]}
      data={state.availableSortColumns}
      dataConnected={state.selectedSortColumns}
      onToolClick={handleToolBarClick}
    />
  );
