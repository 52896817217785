import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GL_APPROVER, GL_REVIEWER, USER_ACTION } from '../../../referenceData';
import Button from '../../core/ui/Button';
import transactionsThunk from '../../../api/thunk/transactions.thunk';

export const ActionPanelView = ({
  error,
  loader,
  transactions,
  userRole,
  onUpdate,
}) => (
  <>
    {error && <p className="gl-error mt-5">{error}</p>}
    {!loader && transactions.length !== 0 && (
      <div className="mt-8 text-2xl">
        {userRole === GL_APPROVER && (
          <Button onClick={() => onUpdate(USER_ACTION.APPROVE)} invertOnHover>
            Approve
          </Button>
        )}
        {userRole === GL_REVIEWER && (
          <Button onClick={() => onUpdate(USER_ACTION.REVIEW)} invertOnHover>
            Review
          </Button>
        )}
        {userRole === GL_REVIEWER && (
          <Button
            onClick={() => onUpdate(USER_ACTION.MANUAL_MATCH)}
            invertOnHover
          >
            Manual Match
          </Button>
        )}
        <Button onClick={() => onUpdate(USER_ACTION.REJECT)} invertOnHover>
          Reject
        </Button>
      </div>
    )}
  </>
);

ActionPanelView.propTypes = {
  transactions: PropTypes.array,
  error: PropTypes.string,
  userRole: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

ActionPanelView.defaultProps = {
  transactions: [],
  userRole: '',
  error: undefined,
  loader: undefined,
};

const mapStoreToProps = (store) => ({
  transactions: store.transactions.data,
  error: store.transactions.error,
  userRole: store.userInfo.role,
  loader: store.common.loader,
});

const mapDispatchToProps = (dispatch) => ({
  onUpdate: (action) =>
    dispatch(transactionsThunk.updateTransactionsStatus({ action })),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ActionPanelView);
