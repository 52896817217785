import '../../../styles/comment.scss';
import { connect } from 'react-redux';
import { Pager } from '@progress/kendo-react-data-tools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import {
  faCalendarAlt,
  faClock,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
import {
  formatDate,
  getCommentTime,
  utcConvert,
} from '../../../utils/dateUtils';
import Link from '../../core/ui/Link';
import { downloadAttachment as downloadAttachmentThunk } from '../../../api/thunk/media.thunk';

export const CommentsView = ({ comments, downloadAttachment }) => {
  const [pageState, setPageState] = useState({
    skip: 0,
    take: 5,
    buttonCount: 5,
    info: true,
    pageSizes: true,
    previousNext: true,
    responsive: true,
  });

  const { skip, take } = pageState;
  const commentsAfterPaging = comments.slice(skip, skip + take);

  const handlePageChange = (event) => {
    const { skip, take } = event; // eslint-disable-line
    setPageState({ ...pageState, skip, take });
  };

  return (
    <>
      <div className="text-3xl text-black mb-4">Comments</div>
      {commentsAfterPaging.map((item) => {
        const utcDate = utcConvert(new Date(item.createdOn));
        const displayDate = formatDate(utcDate);

        const commentFields = [
          {
            className: 'float-left pr-10',
            icon: faUserAlt,
            value: item.user,
          },
          {
            className: 'float-right pr-20',
            icon: faClock,
            value: ` ${getCommentTime(item.createdOn)}`,
          },
          {
            className: 'float-right pr-10',
            icon: faCalendarAlt,
            value: displayDate,
          },
        ];

        return (
          <div key={item.createdOn} className="comment_block">
            <div className="new_comment border border-grey-600">
              <div className="pl-8">
                {commentFields.map((field) => (
                  <div className={field.className}>
                    <FontAwesomeIcon
                      icon={field.icon}
                      style={{ marginRight: '10px' }}
                    />
                    {field.value}
                  </div>
                ))}

                {item.fileName && (
                  <div className="float-right pr-10">
                    <Link // eslint-disable-line
                      className="underline"
                      onClick={() =>
                        downloadAttachment(item.fileId, item.fileName)
                      }
                    >
                      {item.fileName}
                    </Link>
                  </div>
                )}
              </div>
              <ul className="user_comment">
                <div className="comment_body">
                  <div>{item.comment}</div>
                </div>
              </ul>
            </div>
          </div>
        );
      })}
      {comments.length > 0 && (
        <div className="flex">
          <Pager
            className="m-auto"
            skip={skip}
            take={take}
            total={comments.length}
            buttonCount={pageState.buttonCount}
            info={pageState.info}
            type={pageState.type}
            pageSizes={[5, 10, 20]}
            previousNext={pageState.previousNext}
            onPageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

CommentsView.propTypes = {
  comments: PropTypes.array,
  downloadAttachment: PropTypes.func.isRequired,
};

CommentsView.defaultProps = {
  comments: [],
};

export const mapStoreToProps = (store) => ({
  comments: store.auditHistory.data,
});

export const mapDispatchToProps = (dispatch) => ({
  downloadAttachment: (attachmentId, fileName) =>
    dispatch(downloadAttachmentThunk(attachmentId, fileName)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CommentsView);
