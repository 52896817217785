import _ from 'lodash';
import { ACTION_ALLOWED_STATUS_MAPPING, USER_ACTION } from '../referenceData';

const validateUserAction = (
  action,
  selectedRows,
  role,
  attachment,
  comment
) => {
  let error;
  const selectedRowArr = Object.keys(selectedRows);
  const selectedValues = Object.values(selectedRows);
  const allowedStatus = ACTION_ALLOWED_STATUS_MAPPING[role][action];

  if (selectedRowArr.length === 0) {
    error = 'Please select at least one record';
  } else if (
    selectedRowArr.filter(
      (item) => selectedRows[item].matchStatus !== allowedStatus
    ).length > 0
  ) {
    error = `Please select ${allowedStatus} transactions only to ${action}`;
  } else if (
    action === USER_ACTION.MANUAL_MATCH &&
    (!comment || comment.trim() === '' || !attachment)
  ) {
    error = 'Comment and attachment is mandatory for manual match';
  } else if (action !== USER_ACTION.MANUAL_MATCH) {
    const matchIdsToUpdate = _.uniqBy(selectedValues, (e) => e.matchId).map(
      (item) => item.matchId
    );
    if (matchIdsToUpdate.length > 1) {
      error = `Please select single matchId to update, you have selected ${matchIdsToUpdate.join(
        ', '
      )}`;
    }
  }

  return error;
};

export default {
  validateUserAction,
};
