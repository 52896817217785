import React from 'react';
import { TextArea } from '@progress/kendo-react-inputs';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FileUpload from '../../core/ui/FileUpload';
import { SET_ATTACHMENT, SET_COMMENT } from '../../../store/actions';

export const AddCommentView = ({
  comment,
  setComment,
  attachment,
  setAttachment,
}) => {
  const handleChange = (e) => {
    setComment(e.value);
  };

  return (
    <div>
      <div className="flex mt-16 flex-row">
        <div className="flex-1 ">
          <TextArea
            disabled={false}
            maxLength={200}
            value={comment}
            rows={6}
            placeholder="Add your Comment"
            onChange={handleChange}
            style={{ width: '70%' }}
          />
          <div className="flex justify-end" style={{ width: '70%' }}>
            {(comment || '').length} / {200}
          </div>
        </div>
        <div style={{ width: '30%', marginLeft: '-20%' }}>
          <FileUpload file={attachment} setFile={setAttachment} />
        </div>
      </div>
    </div>
  );
};

AddCommentView.propTypes = {
  comment: PropTypes.string,
  setComment: PropTypes.func.isRequired,
  attachment: PropTypes.object,
  setAttachment: PropTypes.func.isRequired,
};

AddCommentView.defaultProps = {
  comment: undefined,
  attachment: undefined,
};

export const mapDispatchToProps = (dispatch) => ({
  setComment: (payload) => dispatch({ type: SET_COMMENT, payload }),
  setAttachment: (payload) => dispatch({ type: SET_ATTACHMENT, payload }),
});

const mapStoreToProps = (store) => ({
  comment: store.auditHistory.comment,
  attachment: store.auditHistory.attachment,
});

export default connect(mapStoreToProps, mapDispatchToProps)(AddCommentView);
