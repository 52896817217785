// import commentsThunk from './comments.thunk';
import transactionThunk from './transactions.thunk';
import filterOptionsThunk from './filterOptions.thunk';
import { LOAD_FILTER_OPTIONS } from '../../store/actions';
import userInfoThunk from './userInfo.thunk';
import { getCurrentPeriod, getCurrentYear } from '../../utils/dateUtils';
import { ROW_COUNT } from '../../referenceData';

const initializeAppThunk = async (dispatch, getState) => {
  const monthYear = {
    year: getCurrentYear(),
    period: getCurrentPeriod(),
  };

  transactionThunk.loadTransactions({
    page: 1,
    rows: ROW_COUNT,
    ...monthYear,
  })(dispatch, getState);
  userInfoThunk.loadUserInfo(dispatch);

  filterOptionsThunk.loadMatchIds(monthYear.period, monthYear.year)(
    dispatch,
    getState
  );

  const [accounts, companies] = await Promise.all([
    filterOptionsThunk.loadAccounts(),
    filterOptionsThunk.loadCompanies(),
  ]);

  dispatch({
    type: LOAD_FILTER_OPTIONS,
    data: { accounts: accounts.data, companies: companies.data },
  });
};

export default initializeAppThunk;
