import React, { useEffect } from 'react';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useHistory, Route } from 'react-router-dom';
import lifecycle from 'page-lifecycle';
import './App.css';
import Login from './components/features/Login';
import Home from './Home';
import { ROUTES } from './referenceData';
import AuthService from './api/auth.service';
import { checkLastTabUnload } from './utils/windowUtils';

const App = () => {
  useEffect(() => {
    const handleUnload = checkLastTabUnload(() =>
      AuthService.revokeTokenBeacon()
    );
    lifecycle.addEventListener('statechange', handleUnload);
    return () => lifecycle.removeEventListener('statechange', handleUnload);
  }, []);

  const customAuthHandler = async () => {
    const previousAuthState =
      AuthService.getOktaAuth().authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      AuthService.triggerLogin();
    } else {
      history.push(ROUTES.LOGIN);
    }
  };

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };
  return (
    <div className="App">
      <Security
        onAuthRequired={customAuthHandler}
        oktaAuth={AuthService.getOktaAuth()}
        restoreOriginalUri={restoreOriginalUri}
      >
        <Route path={ROUTES.LOGIN} exact component={Login} />
        <Route path={ROUTES.HOME} component={LoginCallback} />
        <SecureRoute path={ROUTES.HOME} exact component={Home} />
      </Security>
    </div>
  );
};

export default App;
