import axios from 'axios';
import environment from '../utils/environment';
import AuthService from './auth.service';

const axiosInstance = axios.create({
  baseURL: environment.apiHost(),
  headers: {
    Accept: 'application/json',
  },
});

// Request interceptor for API calls
axiosInstance.interceptors.request.use(
  async (config) => {
    const authState = AuthService.getOktaAuth().authStateManager.getAuthState();
    if (authState) {
      config.headers.Authorization = btoa(
        `${authState.accessToken.accessToken}:${authState.idToken.idToken}`
      );
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosInstance;
