import moment from 'moment';

export const utcConvert = (time) => {
  const date = new Date(time);
  const day = date.getUTCDate();
  const month = date.getUTCMonth();
  const year = date.getUTCFullYear();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();
  return new Date(year, month, day, hour, minute, second);
};

export const formatDate = (date) => {
  const dateComponents = date.toString().split(' ');
  let displayDate;
  if (dateComponents[2] !== undefined) {
    displayDate = `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[3]}`;
  }

  return displayDate || date.toString();
};

export const getCurrentEffectivePeriod = () => moment().format('MMM-YYYY');
export const getCurrentYear = () => moment().format('YYYY');
export const getYearYY = (year) => moment(year).format('YY');
export const getCurrentPeriod = () => moment().format('MMM-MM');
export const getCommentDate = (date) => moment(date).format('DD-MMM-YYYY');
export const getCommentTime = (date) => moment(date).format('HH:mm:ss A');
export const getYearOptions = (count) => {
  const currentYear = parseInt(getCurrentYear(), 10);
  const years = [];
  for (let index = 0; index < count; index += 1) {
    years.push(`${currentYear - index}`);
  }
  return years;
};
