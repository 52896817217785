import { process } from '@progress/kendo-data-query';
import { getGroupingState } from '../../utils';
import {
  LOAD_TRANSACTIONS,
  UNSELECT_ROW,
  SELECT_ROW,
  SET_ERROR,
  RESET_SELECTION,
  UPDATE_STATUS,
  SELECT_ALL,
  RESET_PAGE_SIZE,
} from '../actions';
import { MATCH_STATUS } from '../../referenceData';

const initialState = {
  data: undefined,
  selectedRows: {},
  error: undefined,
  resetPageSizeFlag: false,
};

const TransactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_TRANSACTIONS: {
      return { ...state, data: action.data, totalRecords: action.totalRecords };
    }
    case SET_ERROR: {
      return { ...state, error: action.msg };
    }
    case RESET_PAGE_SIZE: {
      return { ...state, resetPageSizeFlag: !state.resetPageSizeFlag };
    }
    case SELECT_ROW: {
      return {
        ...state,
        selectedRows: {
          ...state.selectedRows,
          [action.payload.transactionId]: {
            matchStatus: action.payload.matchStatus,
            creditAmount: action.payload.transactionCreditAmount,
            debitAmount: action.payload.transactionDebitAmount,
            matchId: action.payload.matchId,
          },
        },
      };
    }
    case SELECT_ALL: {
      const newSelectedRows = { ...state.selectedRows };
      const dataToUpdate = process(
        state.data,
        getGroupingState(action.payload.dataState)
      );
      dataToUpdate.data.forEach((item) => {
        if (
          action.payload.value &&
          item.matchStatus !== MATCH_STATUS.APPROVED
        ) {
          newSelectedRows[item.transactionId] = {
            matchStatus: item.matchStatus,
            creditAmount: item.transactionCreditAmount,
            debitAmount: item.transactionDebitAmount,
            matchId: item.matchId,
          };
        } else {
          delete newSelectedRows[item.transactionId];
        }
      });
      return {
        ...state,
        selectedRows: newSelectedRows,
      };
    }
    case UNSELECT_ROW: {
      const newSelectedRows = { ...state.selectedRows };
      delete newSelectedRows[action.payload.transactionId];
      return {
        ...state,
        selectedRows: newSelectedRows,
      };
    }
    case RESET_SELECTION: {
      return {
        ...state,
        selectedRows: {},
      };
    }
    case UPDATE_STATUS: {
      const { transactionIds, updatedStatus, matchId } = action.payload;
      const transactionsToBeUpdated = state.data.map((item) => {
        if (transactionIds.indexOf(item.transactionId) > -1) {
          return { ...item, matchStatus: updatedStatus, matchId };
        }
        return item;
      });
      return { ...state, data: transactionsToBeUpdated };
    }
    default:
      return state;
  }
};

export default TransactionsReducer;
