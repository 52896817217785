const columns = [
  /* {
    field: 'SELECT',
    title: 'Select',
    width: 100,
    editable: true,
    hidden: false,
    editor: 'boolean',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 1,
    checkboxFilter: false,
    fieldGroup: null,
  }, */
  {
    field: 'matchStatus',
    title: 'Match Status',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 17,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'matchId',
    title: 'Match ID',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: true,
    filterable: true,
    filter: 'text',
    format: '',
    orderIndex: 1,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'ruleId',
    title: 'Match Rule ID',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 16,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'ruleName',
    title: 'Match Rule Name',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'journalNumber',
    title: 'Journal ID',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 2,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'accountingDate',
    title: 'Accounting Date',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'date',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'date',
    format: '{0:dd-MMM-yyyy}',
    orderIndex: 3,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'account',
    title: 'Account',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'numeric',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: true,
    filter: 'text',
    format: '',
    orderIndex: 4,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'lineMemo',
    title: 'Line Memo',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 5,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'transactionDebitAmount',
    title: 'Transaction Debit Amount',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'numeric',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 6,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'transactionCreditAmount',
    title: 'Transaction Credit Amount',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'numeric',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: true,
    filter: 'text',
    format: '',
    orderIndex: 7,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'transactionCurrencyCode',
    title: 'Transaction Currency',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 8,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'transactionDebitMinusCreditAmount',
    title: 'Transaction Debit Minus Credit',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'numeric',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 9,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'journalSource',
    title: 'Journal Source',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 10,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'costCenter',
    title: 'Cost Center',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 11,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'worktag',
    title: 'Worktags',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 12,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'spendCategory',
    title: 'Spend Category',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 13,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'revenueCategory',
    title: 'Revenue Category',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'text',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 14,
    checkboxFilter: false,
    fieldGroup: null,
  },
  {
    field: 'workdayId',
    title: 'External Reference ID',
    width: 200,
    editable: false,
    hidden: false,
    editor: 'numeric',
    headerClassName: '',
    className: '',
    reorderable: false,
    resizable: false,
    locked: false,
    sortable: false,
    filterable: false,
    filter: 'text',
    format: '',
    orderIndex: 15,
    checkboxFilter: false,
    fieldGroup: null,
  },
];

export default {
  columns,
};
