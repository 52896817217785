import '../../styles/main.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Transactions from './Transactions';
import ActionPanel from './ActionPanel';
import Filter from './Filter';
import initializeAppThunk from '../../api/thunk/initializeApp.thunk';
import AddComment from './Comments/AddComment';
import Loader from '../core/ui/Loader';
import Comments from './Comments';
import SortPreferences from './SortPreferences';

const Main = ({ initializeApp, loader }) => {
  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  return (
    <>
      {loader && <Loader />}
      <div className="gl-toolbar text-2xl text-black mb-20 pt-30 pt-24 pl-24">
        <div className="container flex flex-row">
          <div className="w-3/5">
            <Filter />
          </div>
          <div className="w-2/5">
            <SortPreferences />
          </div>
        </div>
      </div>
      <div className="m-24 mt-16 border p-8">
        <Transactions />
        <AddComment />
        <ActionPanel />
      </div>
      <div className="m-24 border p-8">
        <Comments />
      </div>
    </>
  );
};

Main.propTypes = {
  initializeApp: PropTypes.func.isRequired,
  loader: PropTypes.bool,
};

Main.defaultProps = {
  loader: false,
};

const mapStoreToProps = (store) => ({
  loader: store.common.loader,
});

const mapDispatchToProps = (dispatch) => ({
  initializeApp: () => dispatch(initializeAppThunk),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Main);
