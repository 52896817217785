import axios from '../axios';
import { getCurrentEffectivePeriod } from '../../utils/dateUtils';
import {
  hideLoader,
  LOAD_FILTER_OPTIONS,
  SET_FILTERS,
  showLoader,
} from '../../store/actions';
import { ALL } from '../../referenceData';

const loadAccounts = (effectiveDate) => {
  return axios.get(
    `/filter/accounts?effective_date=${
      effectiveDate || getCurrentEffectivePeriod()
    }`
  );
};

const loadAccountsOnPeriodChange =
  (period, year) => async (dispatch, getState) => {
    const state = getState();

    const { account } = state.filters.selectedFilters;
    dispatch(showLoader());
    const res = await loadAccounts(`${period.split('-')[0]} - ${year}`);
    dispatch({ type: LOAD_FILTER_OPTIONS, data: { accounts: res.data } });
    if (account && account !== ALL && res.data.indexOf(account) === -1) {
      dispatch({ type: SET_FILTERS, payload: { account: ALL } });
    }
    dispatch(hideLoader());
  };

const loadMatchIds =
  (period, year, matchStatus) => async (dispatch, getState) => {
    dispatch(showLoader());
    const state = getState();

    const { matchId } = state.filters.selectedFilters;
    const res = await axios.get('/filter/matchIds', {
      params: {
        effective_date: `${period.split('-')[0]} - ${year}`,
        match_status: matchStatus,
      },
    });
    dispatch({ type: LOAD_FILTER_OPTIONS, data: { matchIds: res.data } });
    if (matchId && matchId !== ALL && res.data.indexOf(matchId) === -1) {
      dispatch({ type: SET_FILTERS, payload: { matchId: ALL } });
    }
    dispatch(hideLoader());
  };

const loadCompanies = () => {
  return axios.get('/filter/companies');
};

export default {
  loadAccounts,
  loadCompanies,
  loadAccountsOnPeriodChange,
  loadMatchIds,
};
