import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { LocalizationProvider, IntlProvider } from '@progress/kendo-react-intl';
import gridConfig from './gridConfig';
import '../../../styles/global.css';
import {
  HeaderCell,
  getGridCell,
  getSelectHeaderCell,
  SELECT_CELL,
  getSelectCell,
} from './GridCell';
import { ALL, MATCH_STATUS, ROW_COUNT } from '../../../referenceData';
import {
  RESET_SELECTION,
  SELECT_ALL,
  SELECT_ROW,
  UNSELECT_ROW,
} from '../../../store/actions';
import transactionsThunk from '../../../api/thunk/transactions.thunk';
import { getCreditDebitTally, getGroupingState } from '../../../utils';
import Button from '../../core/ui/Button';

const { columns } = gridConfig;

const initialDataState = {
  skip: 0,
  take: ROW_COUNT,
  sort: [],
  group: [],
};

const Transactions = ({
  transactions,
  selectRow,
  unselectRow,
  loadTransactions,
  filters,
  selectedRows,
  toggleSelectAll,
  totalRecords,
  suppressPaging,
  resetSelection,
  resetPageSizeFlag,
}) => {
  const [dataState, setDataState] = useState({ ...initialDataState });

  useEffect(() => {
    setDataState({ ...initialDataState });
  }, [resetPageSizeFlag]);

  const creditDebitTally = getCreditDebitTally(selectedRows).toFixed(2);

  const [dataResult, setDataResult] = useState(
    process(transactions, dataState)
  );

  useEffect(() => {
    const groupingState = getGroupingState(dataState);
    setDataResult(process(transactions, groupingState));
  }, [transactions, dataState]);

  useEffect(() => {
    loadTransactions({
      page: dataState.skip / dataState.take + 1,
      rows: dataState.take,
      ...filters,
    });
  }, [dataState.skip, dataState.take]);

  const dataStateChange = (event) => {
    const evtState = event.dataState;
    if (evtState.take < dataState.take) {
      resetSelection();
    }

    if (
      evtState.group.length !== dataState.group.length ||
      evtState.filter !== dataState.filter ||
      evtState.sort.length !== dataState.sort.length
    ) {
      setDataState({
        ...dataState,
        group: evtState.group,
        filter: evtState.filter,
        sort: evtState.sort,
      });
    } else {
      setDataState(evtState);
    }
  };

  const handeRowSelect = (item, isSelected) => {
    if (isSelected) {
      selectRow(item);
    } else {
      unselectRow(item);
    }
  };

  const handleSelectAll = useCallback(
    (e) => {
      toggleSelectAll(e.value, dataState);
    },
    [dataState]
  );

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded; // eslint-disable-line
    setDataResult({ ...dataResult });
  };

  let _export;

  const selectCol = useMemo(() => {
    const { data } = process(transactions, getGroupingState(dataState));
    const unapprovedRecords = data.filter((item) => {
      return item.matchStatus !== MATCH_STATUS.APPROVED;
    });
    const isChecked =
      data.length !== 0 &&
      unapprovedRecords.length > 0 &&
      unapprovedRecords.every((item) => !!selectedRows[item.transactionId]);
    return (
      <GridColumn
        field={SELECT_CELL}
        width="100px"
        locked
        editable={false}
        filterable={false}
        headerCell={getSelectHeaderCell(handleSelectAll, isChecked)}
        cell={getSelectCell(handeRowSelect, selectedRows)}
      />
    );
  }, [selectedRows, transactions, handleSelectAll]);

  const gridCols = useMemo(
    () =>
      columns.map((column) => {
        return (
          <GridColumn
            field={column.field}
            title={column.title}
            key={column.field}
            width={column.width}
            sortable
            filterable
            headerCell={HeaderCell}
            cell={getGridCell(column)}
          />
        );
      }),
    [transactions]
  );

  const exportExcel = () => {
    // trigger api to get all records
    _export.save();
  };
  return (
    <>
      <div className="flex mb-5">
        <div className="text-3xl text-black mb-4 flex-1">Transactions</div>
        <div className="text-2xl pt-3 mr-5">
          Total selected records for update:{' '}
          <span className="font-bold">{Object.keys(selectedRows).length}</span>
        </div>
        <div>
          <Button onClick={resetSelection}>Reset</Button>
        </div>
      </div>
      <LocalizationProvider>
        <div>
          <IntlProvider>
            <ExcelExport
              data={transactions}
              ref={(exporter) => {
                _export = exporter;
              }}
            >
              <Grid
                sortable
                filterable
                groupable
                reorderable
                data={{ ...dataResult, total: totalRecords }}
                {...dataState}
                onDataStateChange={dataStateChange}
                expandField="expanded"
                onExpandChange={expandChange}
                pageable={
                  suppressPaging
                    ? undefined
                    : {
                        buttonCount: 4,
                        pageSize: ROW_COUNT,
                        pageSizes: [ROW_COUNT, 500, 2000],
                      }
                }
              >
                <GridToolbar>
                  <div>
                    <button
                      type="button"
                      className="k-grid-toolbar mx-1 rounded-lg p-1 cursor-pointer text-right md-20 text-primary-dark border border-primary-dark hover:bg-primary hover:text-white hover:border-primary"
                      onClick={exportExcel}
                    >
                      Export to Excel
                    </button>
                  </div>
                </GridToolbar>
                {selectCol}
                {gridCols}
              </Grid>
            </ExcelExport>
          </IntlProvider>
        </div>
        <div className="text-center mt-5 text-2xl font-semibold">
          Selected records credit/debit difference ={' '}
          <div className="inline-block w-64 font-semibold text-left">
            {creditDebitTally}
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
};

Transactions.propTypes = {
  transactions: PropTypes.array,
  totalRecords: PropTypes.number,
  loader: PropTypes.bool,
  resetPageSizeFlag: PropTypes.bool.isRequired,
  suppressPaging: PropTypes.bool,
  selectedRows: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  selectRow: PropTypes.func.isRequired,
  unselectRow: PropTypes.func.isRequired,
  loadTransactions: PropTypes.func.isRequired,
  toggleSelectAll: PropTypes.func.isRequired,
  resetSelection: PropTypes.func.isRequired,
};

Transactions.defaultProps = {
  transactions: [],
  totalRecords: 0,
  loader: false,
  suppressPaging: false,
};

const mapStoreToProps = (store) => ({
  transactions: store.transactions.data,
  totalRecords: store.transactions.totalRecords,
  selectedRows: store.transactions.selectedRows,
  error: store.transactions.error,
  resetPageSizeFlag: store.transactions.resetPageSizeFlag,
  suppressPaging:
    !store.common.loader &&
    store.filters.selectedFilters.matchId &&
    store.filters.selectedFilters.matchId !== ALL &&
    store.filters.selectedFilters.matchStatus === MATCH_STATUS.MATCHED,
  filters: store.filters.selectedFilters,
});

const mapDispatchToProps = (dispatch) => ({
  selectRow: (payload) => dispatch({ type: SELECT_ROW, payload }),
  unselectRow: (payload) => dispatch({ type: UNSELECT_ROW, payload }),
  loadTransactions: (params) =>
    dispatch(transactionsThunk.loadTransactions(params)),
  toggleSelectAll: (value, dataState) =>
    dispatch({ type: SELECT_ALL, payload: { value, dataState } }),
  resetSelection: () => dispatch({ type: RESET_SELECTION }),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Transactions);
