import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from '@progress/kendo-react-upload';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ALLOWED_FILE_TYPES } from '../../../referenceData';

const FileUpload = ({ file, setFile }) => {
  const [invalidFile, setInvalidFile] = useState(undefined);

  const onAdd = (evt) => {
    if (
      evt.affectedFiles &&
      evt.affectedFiles[0].validationErrors &&
      evt.affectedFiles[0].validationErrors.length
    ) {
      setFile(undefined);
      setInvalidFile(evt.affectedFiles[0].validationErrors[0]);
      return;
    }
    setInvalidFile(undefined);
    setFile(evt.newState[0]);
  };

  return (
    <>
      <div className="flex">
        <div className="w-40">
          <div className="pb-2">
            <span className="font-bold">Note</span>
          </div>
          <div className="pb-2">
            <span className="font-bold">Allowed types</span>
          </div>
        </div>
        <div className="pb-2">
          <div>
            <span>: File size limit is 5mb</span>
          </div>
          <div>
            <span>: {ALLOWED_FILE_TYPES.join(', ')}</span>
          </div>
        </div>
      </div>
      <Upload
        batch={false}
        multiple={false}
        autoUpload={false}
        onAdd={onAdd}
        restrictions={{
          allowedExtensions: ALLOWED_FILE_TYPES,
          maxFileSize: 5000000,
        }}
      />
      {file && (
        <p className="mt-10 ml-5">
          <FontAwesomeIcon
            className="mr-2"
            icon={faTrash}
            size="lg"
            onClick={() => setFile(undefined)}
          />
          {file.name}
        </p>
      )}
      {invalidFile && <p className="mt-5 ml-5 gl-error">{invalidFile}</p>}
    </>
  );
};

FileUpload.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
  file: undefined,
};

export default FileUpload;
