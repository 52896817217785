import axios from '../axios';
import environment from '../../utils/environment';
import { hideLoader, showLoader } from '../../store/actions';

export const downloadAttachment = (attachmentId, fileName) => (dispatch) => {
  dispatch(showLoader());
  axios
    .get(`${environment.apiHost()}/media/download/${attachmentId}`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      dispatch(hideLoader());
      document.body.removeChild(link);
    });
};
