import { LOAD_AUDIT_HISTORY, SET_ATTACHMENT, SET_COMMENT } from '../actions';

const initialState = {
  data: undefined,
  comment: undefined,
  attachment: undefined,
};

const AuditHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_AUDIT_HISTORY: {
      return { ...state, data: action.payload };
    }
    case SET_COMMENT: {
      return { ...state, comment: action.payload };
    }
    case SET_ATTACHMENT: {
      return { ...state, attachment: action.payload };
    }
    default:
      return state;
  }
};

export default AuditHistoryReducer;
