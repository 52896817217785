import axios from '../axios';
import { LOAD_AUDIT_HISTORY } from '../../store/actions';

const getAuditHistory = (params) => (dispatch) => {
  axios
    .get('/audit-history', {
      params: {
        year: params.year,
        period: params.period.split('-')[0],
      },
    })
    .then((res) => {
      dispatch({ type: LOAD_AUDIT_HISTORY, payload: res.data });
    });
};

export default {
  getAuditHistory,
};
