const apiHost = () => process.env.REACT_APP_API_HOST;
const getOktaUrl = () => process.env.REACT_APP_OKTA_URL;
const getRedirectUri = () => process.env.REACT_APP_REDIRECT_URI;
const getClientId = () => process.env.REACT_APP_CLIENT_ID;

export default {
  apiHost,
  getOktaUrl,
  getRedirectUri,
  getClientId,
};
