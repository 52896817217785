import _ from 'lodash';
import axios from '../axios';
import validation from '../../utils/validation';
import {
  hideLoader,
  LOAD_TRANSACTIONS,
  RESET_SELECTION,
  UPDATE_STATUS,
  setError,
  showLoader,
  SET_COMMENT,
  SET_ATTACHMENT,
} from '../../store/actions';
import { ALL } from '../../referenceData';
import auditHistoryThunk from './auditHistory.thunk';

const loadTransactions = (payload) => async (dispatch, getState) => {
  let params = _.omitBy(payload, (value) => value === ALL);

  if (params.period) {
    params.period = params.period.split('-')[0];
  }

  if (params.matchId) {
    params = _.omit(params, ['page', 'rows']);
  }

  const state = getState();

  const { selectedSortColumns, sortDir } = state.filters.sortPreferences;

  if (selectedSortColumns.length > 0) {
    params.sortBy = selectedSortColumns.map((item) => item.id);
    params.sortDir = sortDir.id;
  }

  dispatch(showLoader());
  // dispatch({ type: RESET_SELECTION });
  dispatch(setError());
  dispatch({ type: SET_COMMENT, payload: '' });
  dispatch({ type: SET_ATTACHMENT });
  const response = await axios.get(`/transactions`, {
    params,
  });
  dispatch({
    type: LOAD_TRANSACTIONS,
    data: response.data.transactions,
    totalRecords: response.data.totalRecords,
  });
  dispatch(hideLoader());
};

const updateTransactionsStatus = (payload) => async (dispatch, getState) => {
  dispatch(setError());
  const state = getState();
  const { period, year } = state.filters.selectedFilters;
  const { selectedRows } = state.transactions;
  const { role } = state.userInfo;
  const { comment, attachment } = state.auditHistory;

  const { action } = payload;

  // validate
  const error = validation.validateUserAction(
    action,
    selectedRows,
    role,
    attachment,
    comment
  );
  if (error) {
    dispatch(setError(error));
    return;
  }
  const transactionIds = Object.keys(selectedRows);
  // trigger backend update if no error
  dispatch(showLoader());
  const formData = new FormData();
  if (attachment) {
    formData.append('attachment', attachment.getRawFile());
  }

  transactionIds.forEach((id) => {
    formData.append('transactions_id', id);
  });

  if (comment && comment.trim() !== '') {
    formData.append('comment', comment.trim());
  }
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const res = await axios.patch(`/transactions/${action}`, formData, config);
  const { status, matchId } = res.data;
  dispatch({
    type: UPDATE_STATUS,
    payload: {
      transactionIds,
      updatedStatus: status,
      matchId,
    },
  });

  dispatch({ type: RESET_SELECTION });
  dispatch({ type: SET_COMMENT, payload: '' });
  dispatch({ type: SET_ATTACHMENT });
  dispatch(hideLoader());

  auditHistoryThunk.getAuditHistory({ year, period })(dispatch);
};

export default {
  loadTransactions,
  updateTransactionsStatus,
};
