import { Provider } from 'react-redux';
import Main from './components/features/Main';
import Layout from './components/core/Layout';
import store from './store';

const Home = () => {
  return (
    <Provider store={store}>
      <Layout>
        <Main />
      </Layout>
    </Provider>
  );
};

export default Home;
