import React from 'react';
import { Loader as KendoLoader } from '@progress/kendo-react-indicators';

const Loader = () => (
  <div className="gl-loader-overlay">
    <div className="gl-loader">
      <KendoLoader size="large" type="infinite-spinner" />
    </div>
  </div>
);

export default Loader;
