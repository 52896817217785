import { useHistory } from 'react-router-dom';
import AuthService from '../../api/auth.service';
import { ROUTES } from '../../referenceData';

const Login = () => {
  const history = useHistory();
  const login = () => {
    const authState = AuthService.getOktaAuth().authStateManager.getAuthState();
    if (!authState || !authState.isAuthenticated) {
      AuthService.triggerLogin();
    }
    history.push(ROUTES.HOME);
  };

  return (
    <div className="flex flex-row items-center justify-center">
      <div>
        <div className="flex flex-col items-center py-10 px-10">
          <button
            type="button"
            onClick={login}
            className="shadow-2xl text-white bg-primary-dark rounded-lg font-bold uppercase px-20 py-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover-white"
          >
            Login with OKTA
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
