import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';
import Button from '../ui/Button';
import AuthService from '../../../api/auth.service';
import { ROUTES } from '../../../referenceData';

const Header = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info);
      });
    } else {
      setUserInfo(null);
    }
  }, [authState, oktaAuth]);

  const logout = () => {
    if (authState && authState.isAuthenticated) {
      AuthService.revokeTokens();
      history.push(ROUTES.LOGIN);
    }
  };

  return (
    <header className="header bg-gray-100">
      <div className="flex flex-row items-center w-full bg-gray">
        <div className="flex flex-row items-center flex-1">
          <div>
            <a href="/">
              <img src="/images/logo.png" alt="" className="logo" />
            </a>
          </div>
        </div>
        <div className="flex flex-row justify-center ">
          <p className="text-2xl font-semibold">
            GL Matching Approval Workflow
          </p>
        </div>
        <div className="flex-1 flex flex-row justify-end">
          <h5 className="text-text-light font-semibold normal-case pr-4 mt-3">
            {userInfo && userInfo.name}
          </h5>
          <Button className="mr-5" onClick={logout}>
            Logout
          </Button>
        </div>
      </div>
    </header>
  );
};

export default Header;
