import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROW_COUNT } from '../../../referenceData';
import Button from '../../core/ui/Button';
import FilterDropdown from './FilterDropdown';
import transactionsThunk from '../../../api/thunk/transactions.thunk';
import {
  RESET_PAGE_SIZE,
  RESET_SELECTION,
  SET_FILTERS,
} from '../../../store/actions';
import { getCurrentPeriod, getCurrentYear } from '../../../utils/dateUtils';
import filterOptionsThunk from '../../../api/thunk/filterOptions.thunk';
import { getFiltersConfig, onFilterChange } from '../../../utils';
import auditHistoryThunk from '../../../api/thunk/auditHistory.thunk';

export const FilterView = ({
  handleSearch,
  accounts,
  companies,
  filters,
  setFilters,
  loadAccountsOnPeriodChange,
  matchIds,
  loadMatchIds,
}) => {
  const handleChange = onFilterChange(
    filters,
    setFilters,
    loadAccountsOnPeriodChange,
    loadMatchIds
  );

  const filterConfig = getFiltersConfig(companies, filters, accounts, matchIds);
  return (
    <div>
      <div className="font-bold text-3xl ml-4 pb-4">Filters</div>
      <div className="inline-flex mb-10 md:flex-wrap">
        {filterConfig.map((config) => (
          <FilterDropdown
            key={config.name}
            {...config}
            onChange={handleChange}
            value={filters[config.name]}
            defaultValue={config.defaultValue}
          />
        ))}
      </div>
      <div className="mt-2 ml-4 mr-10">
        <Button
          className="float-right"
          onClick={() =>
            setFilters({
              period: getCurrentPeriod(),
              year: getCurrentYear(),
            })
          }
        >
          Clear Filters
        </Button>
        <Button
          className="float-right"
          onClick={() => {
            handleSearch(filters);
          }}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

FilterView.propTypes = {
  handleSearch: PropTypes.func.isRequired,
  accounts: PropTypes.array,
  companies: PropTypes.array,
  matchIds: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  loadAccountsOnPeriodChange: PropTypes.func.isRequired,
  loadMatchIds: PropTypes.func.isRequired,
};

FilterView.defaultProps = {
  accounts: [],
  companies: [],
  matchIds: [],
};

const mapStoreToProps = (store) => ({
  accounts: store.filters.options.accounts,
  companies: store.filters.options.companies,
  filters: store.filters.selectedFilters,
  matchIds: store.filters.options.matchIds,
});

const mapDispatchToProps = (dispatch) => ({
  handleSearch: (filters) => {
    dispatch({ type: RESET_SELECTION });
    dispatch({ type: RESET_PAGE_SIZE });
    dispatch(
      transactionsThunk.loadTransactions({
        ...filters,
        rows: ROW_COUNT,
        page: 1,
      })
    );
    dispatch(
      auditHistoryThunk.getAuditHistory({
        year: filters.year,
        period: filters.period,
      })
    );
  },
  setFilters: (filters) => dispatch({ type: SET_FILTERS, payload: filters }),
  loadAccountsOnPeriodChange: ({ period, year }) =>
    dispatch(filterOptionsThunk.loadAccountsOnPeriodChange(period, year)),
  loadMatchIds: ({ period, year, matchStatus }) =>
    dispatch(filterOptionsThunk.loadMatchIds(period, year, matchStatus)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(FilterView);
