import { DropDownList } from '@progress/kendo-react-dropdowns';
import React from 'react';
import PropTypes from 'prop-types';

const FilterDropdown = ({
  label,
  name,
  value,
  onChange,
  options,
  className,
  defaultValue,
}) => (
  <div className="mr-5">
    <div className="flex text-black text-xl p-4">{label}:</div>
    <DropDownList
      name={name}
      value={value || defaultValue}
      onChange={onChange}
      className={className}
      data={options}
    />
  </div>
);

FilterDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FilterDropdown.defaultProps = {
  className: 'flex-1 mt-2',
  value: '',
  defaultValue: '',
};

export default FilterDropdown;
