import { LOAD_USER_INFO } from '../actions';

const TransactionsReducer = (state = {}, action) => {
  switch (action.type) {
    case LOAD_USER_INFO: {
      return { ...state, ...action.data };
    }
    default:
      return state;
  }
};

export default TransactionsReducer;
