import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { formatDate, utcConvert } from '../../../utils/dateUtils';
import { MATCH_STATUS } from '../../../referenceData';

export const SELECT_CELL = 'SELECT';

const checkStyle = {
  color: '#42c5f5',
  fontSize: '15px',
  marginLeft: '30px',
  marginRight: '15px',
  justifyItems: 'center',
  borderColor: '#42c5f5',
  width: '15px',
  height: '15px',
};

export const getSelectHeaderCell = (onChange, checked) => () => {
  return <Checkbox onChange={onChange} checked={checked} style={checkStyle} />;
};

export const HeaderCell = ({ title }) => {
  return (
    <div className="text-center" style={{ height: '24px' }}>
      {title}
    </div>
  );
};

export const getSelectCell =
  (onRowSelect, selectedRow) =>
  ({ dataItem, className, style }) => /* eslint-disable-line */
  {
    return (
      <td className={className} style={style}>
        <Checkbox
          onChange={(e) => {
            onRowSelect(dataItem, e.value);
          }}
          checked={!!selectedRow[dataItem.transactionId]} // eslint-disable-line
          disabled={dataItem.matchStatus === MATCH_STATUS.APPROVED} // eslint-disable-line
          style={checkStyle}
        />
      </td>
    );
  };

HeaderCell.propTypes = {
  title: PropTypes.string.isRequired,
};

export const getGridCell =
  (column) =>
    ({ dataItem }) => { // eslint-disable-line
    if (column.field === 'ACCOUNTING_DATE') {
      const utcDate = utcConvert(new Date(dataItem[column.field]));
      const formattedDate = formatDate(utcDate);
      return <td className="text-center">{formattedDate}</td>;
    }

    return <td className="text-center">{dataItem[column.field]}</td>;
  };
