import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { FooterLink } from '../ui/Link';
import { getCurrentYear } from '../../../utils/dateUtils';

const Footer = () => (
  <div className="footer">
    <div className="text-center text-2xl text-black">
      <p>© {getCurrentYear()} Snowflake Inc. All Rights Reserved</p>
      <FooterLink
        url="https://twitter.com/SnowflakeDB"
        icon={faTwitter}
        padding
      />
      <FooterLink
        url="https://www.linkedin.com/company/3653845"
        icon={faLinkedin}
        padding
      />
      <FooterLink
        url="https://www.youtube.com/user/snowflakecomputing"
        icon={faYoutube}
      />
      <FooterLink
        url="https://www.facebook.com/Snowflake-Computing-709171695819345/"
        icon={faFacebook}
        isLast
      />
    </div>
  </div>
);

export default Footer;
