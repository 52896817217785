import { ACTIVE_TABS } from '../referenceData';
import { getUniqueId } from './index';

export const setWindowId = () => {
  if (!window.name) {
    window.name = getUniqueId();
  }
  let activeTabs = localStorage.getItem(ACTIVE_TABS);

  if (!activeTabs) {
    activeTabs = [];
    activeTabs.push(window.name);
  } else {
    activeTabs = JSON.parse(activeTabs);
  }

  if (activeTabs.indexOf(window.name) === -1) {
    activeTabs.push(window.name);
  }

  localStorage.setItem(ACTIVE_TABS, JSON.stringify(activeTabs));
};

export const checkLastTabUnload = (callback) => (e) => {
  if (e.newState === 'terminated') {
    const activeTabs = JSON.parse(localStorage.getItem(ACTIVE_TABS));
    activeTabs.splice(activeTabs.indexOf(window.name), 1);
    localStorage.setItem(ACTIVE_TABS, JSON.stringify(activeTabs));
    if (activeTabs.length === 0) {
      callback();
    }
  }
};
