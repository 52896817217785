import { combineReducers } from 'redux';
import transactionsReducer from './transactionsReducer';
import auditHistoryReducer from './auditHistoryReducer';
import filterReducer from './filterReducer';
import userInfoReducer from './userInfoReducer';
import commonReducer from './commonReducer';

const rootReducer = combineReducers({
  transactions: transactionsReducer,
  auditHistory: auditHistoryReducer,
  filters: filterReducer,
  userInfo: userInfoReducer,
  common: commonReducer,
});

export default rootReducer;
