import { SHOW_LOADER, HIDE_LOADER } from '../actions';

const CommonReducer = (state = {}, action) => {
  switch (action.type) {
    case SHOW_LOADER: {
      return { ...state, loader: true };
    }
    case HIDE_LOADER: {
      return { ...state, loader: false };
    }
    default:
      return state;
  }
};

export default CommonReducer;
